<template>
  <b-card>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>

    <div>
      <b-row>
        <b-col
          v-for="(row, index) in rows"
          :key="index"
          sm="6"
          lg="4"
          cols="12"
          class="mb-2"
        >
          <training-item
            :training="row"
            @completed="loadItems"
          />
        </b-col>
      </b-row>
    </div>
    <section v-if="rows.length === 0">
      <div class="text-center m-4">
        <b-avatar
          size="72"
          variant="primary"
          class="mr-2"
        >
          <feather-icon
            icon="SearchIcon"
            size="42"
          />
        </b-avatar>
        <h3 class="mt-1">
          No Training found
        </h3>
        <p class="text-muted">
          There weren't any Training modules found or available for you to complete.
        </p>
      </div>
    </section>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BCol,
  BAvatar
} from "bootstrap-vue";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import trainingsService from "@/services/trainingsService";
import TrainingItem from "./TrainingItem.vue";
import { userRoles } from "@/models";
import { makeErrorToast } from "@/libs/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    AomSkeletonLoader,
    TrainingItem,
    BAvatar
  },
  data() {
    return {
      rows: [],
      interval: null,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),
  },
  created() {
    this.loadItems();
    this.interval = setInterval(() => {
      this.loadItems();
    }, 30000); // 30 seconds
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await trainingsService.getUserTrainings(
          this.program.id,
          this.profile.id
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          roles: item.roles
            .map(r => {
              if (r.id === userRoles.MENTOR) {
                return this.mentorDisplay;
              }
              if (r.id === userRoles.MENTEE) {
                return this.menteeDisplay;
              }
              return "";
            })
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Training list not loaded."));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
