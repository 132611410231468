<template>
  <b-card
    border-variant="primary"
    tag="article"
    class="h-100"
  >
    <b-card-text>
      <b-card-title class="text-truncate mb-1">
        {{ training.course_name }}
      </b-card-title>
      <p class="mb-0">
        <b-badge
          v-for="role in training.roles"
          :key="role"
          variant="primary"
          class="font-weight-normal"
          style="margin-right: 2px;"
        >
          {{ role }}
        </b-badge>
      </p>
    </b-card-text>

    <b-card-text>
      <h6
        v-if="firstAccessDate"
        class="text-base font-weight-medium mb-1"
      >
        Started on <span class="text-body-1">{{ formatDate(firstAccessDate) }}</span>
      </h6>
      <h6
        v-if="completedDate && isCompleted"
        class="text-base font-weight-medium mb-1"
      >
        Completed on <span class="text-body-1">{{ formatDate(completedDate) }}</span>
      </h6>
    </b-card-text>

    <b-dropdown-divider style="list-style-type: none" />

    <b-card-text>
      <div
        class="
          d-flex
          flex-column
          text-sm
          mt-1
          mb-1
        "
      >
        <span v-if="spent">Time spent: {{ spent }}</span>
      </div>

      <b-progress
        :value="Number(isCompleted)"
        :max="max"
        class="mb-2"
      />

      <b-button
        :disabled="isLauching"
        variant="primary"
        @click="launchProgramTraining(training.id)"
      >
        <b-spinner
          v-if="isLauching"
          small
        />
        <span v-else>{{ isCompleted ? "Re-launch" : "Launch" }}</span>
      </b-button>

      <b-button
        v-if="!isCompleted"
        :disabled="isUpdating"
        class="ml-1"
        variant="outline-primary"
        @click="markProgramTrainingComplete(training.id)"
      >
        <b-spinner
          v-if="isUpdating"
          small
        />
        <span v-else>I have completed this training</span>
      </b-button>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BButton,
  BCardText,
  BCard,
  BSpinner,
  BCardTitle,
  BDropdownDivider,
  BProgress,
  BBadge,
} from "bootstrap-vue";
import trainingsService from "@/services/trainingsService";
import { trainingStatus } from "@/models";
import { makeSuccessToast, makeErrorToast, localeDateStringFromIsoDateTime } from '@/libs/utils';

export default {
  components: {
    BButton,
    BCardText,
    BCard,
    BSpinner,
    BCardTitle,
    BDropdownDivider,
    BProgress,
    BBadge,
  },
  props: {
    training: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      max: 1,
      rows: [],
      isLauching: false,
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),

    trainingDetail() {
      if (!this.training || !this.training.program_training_users) {
        return null;
      }
      return this.training.program_training_users[
        this.training.program_training_users.length - 1
      ];
    },

    firstAccessDate() {
      if (!this.trainingDetail || !this.trainingDetail.first_access_date) {
        return null;
      }

      return this.trainingDetail.first_access_date;
    },

    completedDate() {
      if (!this.trainingDetail) {
        return null;
      }

      return this.trainingDetail.completed_date;
    },

    spent() {
      if (!this.trainingDetail) {
        return 'Not started';
      }

      const spentTime = this.trainingDetail.total_seconds_tracked || 0;

      if (spentTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }

      return `${Math.round(spentTime / 60)} minutes`;
    },

    isCompleted() {
      if (!this.trainingDetail) {
        return false;
      }
      if (!this.trainingDetail.status) {
        return false;
      }

      return this.trainingDetail.status.id === trainingStatus.COMPLETE;
    },
  },
  methods: {
    async launchProgramTraining(trainingId) {
      this.isLauching = true;
      try {
        const response = await trainingsService.launchProgramTraining(
          this.program.id,
          trainingId
        );
        if (response && response.data) {
          window.open(response.data.launch_link);
        }
      } catch (e) {
        const { status, data } = e.response;
        if (status === 404 || status === 400 || status === 500) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        }
        this.$log.error(e);
      } finally {
        this.isLauching = false;
      }
    },

    async markProgramTrainingComplete(trainingId) {
      this.isUpdating = true;
      try {
        const response = await trainingsService.markProgramTrainingsComplete(
          this.program.id,
          {trainings: [{ id: trainingId }]}
        );
        if (response && response.data) {
          this.$toast(makeSuccessToast("Training has been successfully marked as completed."));
          this.$emit('completed');
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },

    formatDate(datetime) {
      if (datetime) {
        return localeDateStringFromIsoDateTime(datetime, true);
      }
    },
  },
};
</script>
